<template>
  <PaymentType
    :payment-data="payment"
    @update="updateFormValue"
  />
</template>

<script>
import PaymentType from '../Partials/PaymentType'
import updateFormValue from '../../../mixins/updateFormValue'

export default {
  mixins: [updateFormValue],
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  components: {
    PaymentType,
  },
  data: () => ({
    emitAllowed: false,
    payment: {
      paymentType: null,
      paymentDueDays: null,
    }
  }),
  watch: {
    payment: {
      deep: true,
      handler (payment) {
        if (this.emitAllowed) this.$emit('update', payment)
      }
    }
  },
  mounted () {
    const { paymentType, paymentDueDays } = this.data.payment
    this.payment = {
      paymentType,
      paymentDueDays
    }
    this.$nextTick(() => {
      this.emitAllowed = true
    })
  },
}
</script>
